/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum VisitPaymentMethod {
    CASH = 'CASH',
    CARD = 'CARD',
    BANK_TRANSFER = 'BANK_TRANSFER',
    WEBPAY = 'WEBPAY',
    TICKET = 'TICKET',
    SUBSCRIPTION = 'SUBSCRIPTION',
    FT_TICKET = 'FT_TICKET',
    FREE = 'FREE',
}
