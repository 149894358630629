import { createGettext } from 'vue3-gettext';
import translations from '../language/translations.json';

const gettext = createGettext({
  availableLanguages: {
    en: 'Anglicky',
    cs: 'Česky',
    sk: 'Slovensky',
  },
  defaultLanguage: navigator.language.slice(0, 2),
  translations: translations,
  silent: true,
});

export default gettext;
