/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckVisitSchema } from '../models/CheckVisitSchema';
import type { CreateVisitSchema } from '../models/CreateVisitSchema';
import type { FTActionVisitPatchSchema } from '../models/FTActionVisitPatchSchema';
import type { FTTicketActionVisitDetailSchema } from '../models/FTTicketActionVisitDetailSchema';
import type { PagedVisitListSchema } from '../models/PagedVisitListSchema';
import type { PatchVisitSchema } from '../models/PatchVisitSchema';
import type { ReceiptReadSchema } from '../models/ReceiptReadSchema';
import type { RecurringVisitDeleteStrategyChoices } from '../models/RecurringVisitDeleteStrategyChoices';
import type { UpdateVisitSchema } from '../models/UpdateVisitSchema';
import type { VisitDetailSchema } from '../models/VisitDetailSchema';
import type { VisitFormSchema } from '../models/VisitFormSchema';
import type { VisitImageSchema } from '../models/VisitImageSchema';
import type { VisitReceiptCreateSchema } from '../models/VisitReceiptCreateSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class VisitsApi {
    /**
     * Cancel Visit Receipts
     * Cancel receipts for this visit and refund the subscription/ticket if applicable.
     * @returns VisitDetailSchema OK
     * @throws ApiError
     */
    public static visitsCancelVisitReceiptsPost({
        visitId,
    }: {
        visitId: string,
    }): CancelablePromise<VisitDetailSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/visits/{visit_id}/cancel-receipts/',
            path: {
                'visit_id': visitId,
            },
        });
    }
    /**
     * Check Visit
     * Check visit validity
     * @returns void
     * @throws ApiError
     */
    public static visitsCheckVisitPost({
        requestBody,
    }: {
        requestBody: CheckVisitSchema,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/visits/validate/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create Visit
     * Create a new visit. <br>Required Permission: create_visit
     * @returns VisitDetailSchema Created
     * @throws ApiError
     */
    public static visitsCreateVisitPost({
        requestBody,
    }: {
        requestBody: CreateVisitSchema,
    }): CancelablePromise<VisitDetailSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/visits/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Visit List
     * Get list of visits.<br>Use order_by'-date' or 'date' or '-end_date' or '-end_date'. <br>Required Permission: list_visits
     * @returns PagedVisitListSchema OK
     * @throws ApiError
     */
    public static visitsGetVisitListGet({
        query,
        patientId,
        userId,
        roomId,
        waiting,
        startDate,
        endDate,
        searchTerm,
        paid,
        orderBy,
        limit = 100,
        offset,
    }: {
        query?: 'NEXT' | 'PAST' | 'ALL',
        patientId?: (string | null),
        userId?: (string | null),
        roomId?: (string | null),
        waiting?: (boolean | null),
        startDate?: (string | null),
        endDate?: (string | null),
        searchTerm?: (string | null),
        paid?: (boolean | null),
        orderBy?: (string | null),
        limit?: number,
        offset?: number,
    }): CancelablePromise<PagedVisitListSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/visits/',
            query: {
                'query': query,
                'patient_id': patientId,
                'user_id': userId,
                'room_id': roomId,
                'waiting': waiting,
                'start_date': startDate,
                'end_date': endDate,
                'search_term': searchTerm,
                'paid': paid,
                'order_by': orderBy,
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * Delete Visit
     * Delete visit. <br>Required Permission: remove_visit
     * @returns void
     * @throws ApiError
     */
    public static visitsDeleteVisitDelete({
        visitId,
        recurringVisitsStrategy,
    }: {
        visitId: string,
        recurringVisitsStrategy?: (RecurringVisitDeleteStrategyChoices | null),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/visits/{visit_id}/',
            path: {
                'visit_id': visitId,
            },
            query: {
                'recurring_visits_strategy': recurringVisitsStrategy,
            },
        });
    }
    /**
     * Get Visit
     * Update visit. <br>Required Permission: get_visit_detail
     * @returns VisitDetailSchema OK
     * @throws ApiError
     */
    public static visitsGetVisitGet({
        visitId,
    }: {
        visitId: string,
    }): CancelablePromise<VisitDetailSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/visits/{visit_id}/',
            path: {
                'visit_id': visitId,
            },
        });
    }
    /**
     * Patch Visit
     * Patch visit. <br>Required Permission: update_visit
     * @returns VisitDetailSchema OK
     * @throws ApiError
     */
    public static visitsPatchVisitPatch({
        visitId,
        requestBody,
    }: {
        visitId: string,
        requestBody: PatchVisitSchema,
    }): CancelablePromise<VisitDetailSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/visits/{visit_id}/',
            path: {
                'visit_id': visitId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Visit
     * Update visit. <br>Required Permission: update_visit
     * @returns VisitDetailSchema OK
     * @throws ApiError
     */
    public static visitsUpdateVisitPut({
        visitId,
        requestBody,
    }: {
        visitId: string,
        requestBody: UpdateVisitSchema,
    }): CancelablePromise<VisitDetailSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/visits/{visit_id}/',
            path: {
                'visit_id': visitId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Visit Form
     * Delete visit-form. <br>Required permission: delete_visit_form
     * @returns void
     * @throws ApiError
     */
    public static visitsDeleteVisitFormDelete({
        formId,
    }: {
        formId: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/visits/visit-forms/{form_id}/',
            path: {
                'form_id': formId,
            },
        });
    }
    /**
     * Delete Visit Image
     * Delete visit-image. <br>Required permission: delete_visit_image
     * @returns void
     * @throws ApiError
     */
    public static visitsDeleteVisitImageDelete({
        imageId,
    }: {
        imageId: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/visits/visit-images/{image_id}/',
            path: {
                'image_id': imageId,
            },
        });
    }
    /**
     * Get Visit Form List
     * List visit forms. <br>Required Permission: list_visit_forms
     * @returns VisitFormSchema OK
     * @throws ApiError
     */
    public static visitsGetVisitFormListGet({
        visitId,
    }: {
        visitId: string,
    }): CancelablePromise<Array<VisitFormSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/visits/{visit_id}/visit-forms/',
            path: {
                'visit_id': visitId,
            },
        });
    }
    /**
     * Upload Visit Form
     * Upload visit form. <br>Required Permission: update_visit
     * @returns VisitFormSchema Created
     * @throws ApiError
     */
    public static visitsUploadVisitFormPost({
        visitId,
        formData,
    }: {
        visitId: string,
        formData: {
            file: Blob;
        },
    }): CancelablePromise<VisitFormSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/visits/{visit_id}/visit-forms/',
            path: {
                'visit_id': visitId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Get Visit Image List
     * List visit images. <br>Required Permission: list_visit_images
     * @returns VisitImageSchema OK
     * @throws ApiError
     */
    public static visitsGetVisitImageListGet({
        visitId,
    }: {
        visitId: string,
    }): CancelablePromise<Array<VisitImageSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/visits/{visit_id}/visit-images/',
            path: {
                'visit_id': visitId,
            },
        });
    }
    /**
     * Upload Visit Image
     * Upload visit image. <br>Required Permission: update_visit
     * @returns VisitImageSchema Created
     * @throws ApiError
     */
    public static visitsUploadVisitImagePost({
        visitId,
        formData,
    }: {
        visitId: string,
        formData: {
            image: Blob;
        },
    }): CancelablePromise<VisitImageSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/visits/{visit_id}/visit-images/',
            path: {
                'visit_id': visitId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Patch Ft Action Visit
     * Patch visit. <br>Required Permission: update_ft_action_visit
     * @returns FTTicketActionVisitDetailSchema OK
     * @throws ApiError
     */
    public static visitsPatchFtActionVisitPatch({
        visitId,
        ftActionVisitId,
        requestBody,
    }: {
        visitId: string,
        ftActionVisitId: string,
        requestBody: FTActionVisitPatchSchema,
    }): CancelablePromise<FTTicketActionVisitDetailSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/visits/{visit_id}/ft-action-visits/{ft_action_visit_id}/',
            path: {
                'visit_id': visitId,
                'ft_action_visit_id': ftActionVisitId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Pay For Visit
     * Pay for this visit and create a receipt. Required Permission: create_self_receipt
     * @returns ReceiptReadSchema OK
     * @throws ApiError
     */
    public static visitsPayForVisitPost({
        visitId,
        requestBody,
    }: {
        visitId: string,
        requestBody: VisitReceiptCreateSchema,
    }): CancelablePromise<Array<ReceiptReadSchema>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/visits/{visit_id}/pay-for-visit/',
            path: {
                'visit_id': visitId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
