/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MedicalActionListSchema } from './MedicalActionListSchema';
export type FTVisitTicketActionSchema = {
    id: string;
    ft_ticket_action_id: string;
    medical_action: MedicalActionListSchema;
    count: number;
    points_total: number;
    visit_date?: (string | null);
    visit_id?: (string | null);
    user_full_name: string;
    k_batch_id: (string | null);
    k_batch_batch_number: (number | null);
    is_locked: boolean;
    type?: FTVisitTicketActionSchema.type;
};
export namespace FTVisitTicketActionSchema {
    export enum type {
        VISIT = 'visit',
    }
}

