<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  position?: string;
  removePadding?: boolean;
  setWidth?: boolean;
  maxWidth?: number;
  setJustify?: boolean;
  setBorder?: boolean;
  newVisit?: boolean;
  alwaysCentered?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  position: 'center',
  alwaysCentered: false,
  maxWidth: undefined,
});

defineEmits(['close']);

const modalClasses = computed(() => {
  return [
    props.position === 'bot' ? 'modal-container__bottom' : null,
    props.alwaysCentered ? 'modal-container--always-centered' : null,
  ];
});
</script>

<template>
  <div class="modal-container" :class="modalClasses" @click="$emit('close')">
    <div
      class="modal"
      :class="{
        'set-width': setWidth,
        'modal--top': position === 'top',
      }"
      :style="maxWidth ? `max-width: ${maxWidth}rem` : undefined"
      @click.stop=""
    >
      <div
        v-if="$slots.header"
        :class="{ 'justify-end': setJustify }"
        class="modal__header bg-dark-300 text-white text-bold text-center text-uppercase column justify-center items-center q-px-lg"
      >
        <slot name="header"></slot>
      </div>

      <div
        v-if="$slots.body"
        class="modal__body row justify-between"
        :class="[
          removePadding ? 'q-pa-none' : 'q-px-lg q-py-lg',
          setBorder && 'modal__body--border',
        ]"
      >
        <slot name="body"></slot>
      </div>

      <div
        v-if="$slots.footer"
        class="modal__footer q-px-lg q-py-lg text-right row justify-between items-center"
        :class="{ 'modal__footer--new-visit': newVisit }"
      >
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.set-width {
  min-width: 66.2rem;
}

.justify-end {
  justify-content: flex-end;
}

@media only screen and (max-width: 899px) {
  .set-width {
    min-width: 80vw;
  }
}

@media only screen and (max-width: 599px) {
  .set-width {
    min-width: 90vw;
  }
}
</style>
