/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum PermissionsCodename {
    CREATE_ORDER = 'create_order',
    PAY_ORDER = 'pay_order',
    CREATE_SERVICE = 'create_service',
    GET_SERVICE_DETAIL = 'get_service_detail',
    UPDATE_SERVICE = 'update_service',
    REMOVE_SERVICE = 'remove_service',
    EDIT_COMPANY_DETAILS = 'edit_company_details',
    GET_COLLEAGUE = 'get_colleague',
    GET_SERVICE_GROUP_DETAIL = 'get_service_group_detail',
    CREATE_SERVICE_GROUP = 'create_service_group',
    UPDATE_SERVICE_GROUP = 'update_service_group',
    REMOVE_SERVICE_GROUP = 'remove_service_group',
    LIST_COLLEAGUES = 'list_colleagues',
    LIST_COLLEAGUE_SUGGESTIONS = 'list_colleague_suggestions',
    UPDATE_COLLEAGUE = 'update_colleague',
    UPDATE_COLLEAGUE_ROLE = 'update_colleague_role',
    INVITE_COLLEAGUES = 'invite_colleagues',
    CREATE_PATIENT = 'create_patient',
    LIST_PATIENTS = 'list_patients',
    SEND_PATIENT_SMS = 'send_patient_sms',
    SEND_PATIENT_EMAIL = 'send_patient_email',
    GET_PATIENT_DETAIL = 'get_patient_detail',
    UPDATE_PATIENT = 'update_patient',
    REMOVE_PATIENT = 'remove_patient',
    MERGE_PATIENTS = 'merge_patients',
    CREATE_VISIT = 'create_visit',
    GET_VISIT_DETAIL = 'get_visit_detail',
    LIST_TICKETS = 'list_tickets',
    LIST_PATIENT_TICKETS = 'list_patient_tickets',
    UPDATE_VISIT = 'update_visit',
    CREATE_TICKET = 'create_ticket',
    UPDATE_TICKET = 'update_ticket',
    GET_TICKET_DETAIL = 'get_ticket_detail',
    CREATE_PATIENT_TICKET = 'create_patient_ticket',
    UPDATE_PATIENT_TICKET = 'update_patient_ticket',
    DELETE_PATIENT_TICKET = 'delete_patient_ticket',
    LIST_VISITS = 'list_visits',
    LIST_VISIT_FORMS = 'list_visit_forms',
    LIST_VISIT_IMAGES = 'list_visit_images',
    DELETE_VISIT_FORM = 'delete_visit_form',
    DELETE_VISIT_IMAGE = 'delete_visit_image',
    REMOVE_VISIT = 'remove_visit',
    LIST_MY_COMPANY_SETTINGS = 'list_my_company_settings',
    LIST_MY_COMPANY_INSURANCE_SETTINGS = 'list_my_company_insurance_settings',
    LIST_MY_COMPANY_ORDERS = 'list_my_company_orders',
    LIST_MY_COMPANY_SERVICES = 'list_my_company_services',
    GET_MY_COMPANY = 'get_my_company',
    LIST_PACKAGES = 'list_packages',
    LIST_INCOME_STATS = 'list_income_stats',
    LIST_NEW_PATIENTS_STATS = 'list_new_patients_stats',
    LIST_PHYSIO_VISIT_STATS = 'list_physio_visit_stats',
    LIST_SERVICE_CATEGORY_PATIENT_STATS = 'list_service_category_patient_stats',
    LIST_SERVICE_CATEGORY_STATS = 'list_service_category_stats',
    LIST_VISIT_STATS = 'list_visit_stats',
    GET_ME = 'get_me',
    UPDATE_ME = 'update_me',
    PASSWORD_CHANGE = 'password_change',
    CREATE_COLLEAGUE_WORKING_HOURS = 'create_colleague_working_hours',
    GET_COLLEAGUE_WORKING_HOURS = 'get_colleague_working_hours',
    UPDATE_COLLEAGUE_WORKING_HOURS = 'update_colleague_working_hours',
    CREATE_MY_WORKING_HOURS = 'create_my_working_hours',
    GET_MY_WORKING_HOURS = 'get_my_working_hours',
    UPDATE_MY_WORKING_HOURS = 'update_my_working_hours',
    GET_MY_TIME_OFF = 'get_my_time_off',
    CREATE_MY_TIME_OFF = 'create_my_time_off',
    UPDATE_MY_TIME_OFF = 'update_my_time_off',
    DELETE_MY_TIME_OFF = 'delete_my_time_off',
    GET_COLLEAGUE_TIME_OFF = 'get_colleague_time_off',
    CREATE_COLLEAGUE_TIME_OFF = 'create_colleague_time_off',
    UPDATE_COLLEAGUE_TIME_OFF = 'update_colleague_time_off',
    DELETE_COLLEAGUE_TIME_OFF = 'delete_colleague_time_off',
    GET_RECEIPT = 'get_receipt',
    LIST_RECEIPTS = 'list_receipts',
    CREATE_SELF_RECEIPT = 'create_self_receipt',
    CANCEL_SELF_RECEIPT = 'cancel_self_receipt',
    UPDATE_SELF_RECEIPT = 'update_self_receipt',
    CREATE_RECEIPT_FOR_COLLEAGUE = 'create_receipt_for_colleague',
    UPDATE_RECEIPT_FOR_COLLEAGUE = 'update_receipt_for_colleague',
    REMOVE_RECEIPT = 'remove_receipt',
    CANCEL_RECEIPT_FOR_COLLEAGUE = 'cancel_receipt_for_colleague',
    GET_RECEIPT_FOR_COLLEAGUE = 'get_receipt_for_colleague',
    GET_COMPANY_REWARD_PROGRESS = 'get_company_reward_progress',
    CREATE_SUBSCRIPTION = 'create_subscription',
    CREATE_SUBSCRIPTION_PAYMENT = 'create_subscription_payment',
    GET_SUBSCRIPTION = 'get_subscription',
    LIST_SUBSCRIPTIONS = 'list_subscriptions',
    GET_SUBSCRIPTION_PAYMENT = 'get_subscription_payment',
    LIST_SUBSCRIPTION_PAYMENTS = 'list_subscription_payments',
    UPDATE_SUBSCRIPTION = 'update_subscription',
    UPDATE_SUBSCRIPTION_PAYMENT = 'update_subscription_payment',
    GET_COLLEAGUE_VISIT_DETAIL = 'get_colleague_visit_detail',
    GET_ROOM_DETAIL = 'get_room_detail',
    LIST_ROOMS = 'list_rooms',
    UPDATE_ROOM = 'update_room',
    CREATE_ROOM = 'create_room',
    GET_TEMPLATE_DETAIL = 'get_template_detail',
    LIST_TEMPLATES = 'list_templates',
    UPDATE_TEMPLATE = 'update_template',
    CREATE_TEMPLATE = 'create_template',
    REMOVE_TEMPLATE = 'remove_template',
    LIST_AUDIT_LOG = 'list_audit_log',
    EXPORT_PATIENTS = 'export_patients',
    EXPORT_PATIENTS_AND_VISITS = 'export_patients_and_visits',
    DELETE_COLLEAGUE_VISIT = 'delete_colleague_visit',
    VIEW_SETTINGS_TAB = 'view_settings_tab',
    CREATE_PATIENT_FT_TICKET = 'create_patient_ft_ticket',
    UPDATE_PATIENT_FT_TICKET = 'update_patient_ft_ticket',
    REMOVE_PATIENT_FT_TICKET = 'remove_patient_ft_ticket',
    GET_PATIENT_FT_TICKET_DETAIL = 'get_patient_ft_ticket_detail',
    GET_PATIENT_FT_TICKET_LIST = 'get_patient_ft_ticket_list',
    UPLOAD_PATIENT_FT_TICKET_IMAGE = 'upload_patient_ft_ticket_image',
    VIEW_STATISTICS_TAB = 'view_statistics_tab',
    VIEW_INSURANCES_TAB = 'view_insurances_tab',
    GET_K_BATCH_LIST = 'get_k_batch_list',
    GET_K_BATCH_DETAIL = 'get_k_batch_detail',
    CREATE_K_BATCH = 'create_k_batch',
    REMOVE_K_BATCH = 'remove_k_batch',
    GET_COMPANY_INSURANCE_WORKSPACE_LIST = 'get_company_insurance_workspace_list',
    GET_COMPANY_INSURANCE_WORKSPACE_DETAIL = 'get_company_insurance_workspace_detail',
    CREATE_COMPANY_INSURANCE_WORKSPACE = 'create_company_insurance_workspace',
    UPDATE_COMPANY_INSURANCE_WORKSPACE = 'update_company_insurance_workspace',
    REMOVE_COMPANY_INSURANCE_WORKSPACE = 'remove_company_insurance_workspace',
    GET_INSURANCE_COMPANY_CONTRACT_DETAIL = 'get_insurance_company_contract_detail',
    CREATE_INSURANCE_COMPANY_CONTRACT = 'create_insurance_company_contract',
    UPDATE_INSURANCE_COMPANY_CONTRACT = 'update_insurance_company_contract',
    REMOVE_INSURANCE_COMPANY_CONTRACT = 'remove_insurance_company_contract',
    GET_DIAGNOSIS_LIST = 'get_diagnosis_list',
    GET_EXPERTISE_LIST = 'get_expertise_list',
    GET_MEDICAL_ACTION_LIST = 'get_medical_action_list',
    GET_INSURANCE_COMPANY_LIST = 'get_insurance_company_list',
    GET_INSURANCE_WORKSPACE_LIST = 'get_insurance_workspace_list',
    GET_INSURANCE_WORKSPACE_DETAIL = 'get_insurance_workspace_detail',
    CREATE_INSURANCE_WORKSPACE = 'create_insurance_workspace',
    UPDATE_INSURANCE_WORKSPACE = 'update_insurance_workspace',
    GET_K_BATCH_DOCUMENT_LIST = 'get_k_batch_document_list',
    UPDATE_FT_ACTION_VISIT = 'update_ft_action_visit',
    DELETE_VISIT_REVIEW = 'delete_visit_review',
    GET_VISIT_REVIEW_LIST = 'get_visit_review_list',
    GET_FILTERED_MEDICAL_ACTION_LIST = 'get_filtered_medical_action_list',
    EXPORT_MEDICAL_ACTION_LIST = 'export_medical_action_list',
    RESEND_RECEIPT_EMAIL = 'resend_receipt_email',
}
