/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum InsuranceTypeOptions {
    PUBLIC = 'PUBLIC',
    CONTRACTUAL = 'CONTRACTUAL',
    TRAVEL = 'TRAVEL',
    INTERNATIONAL = 'INTERNATIONAL',
}
