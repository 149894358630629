<script setup lang="ts">
import BaseModal from './BaseModal.vue';
import gettext from 'src/utils/gettext';

const { $gettext } = gettext;

defineEmits(['closeModal']);
</script>

<template>
  <BaseModal :set-border="true" :max-width="100" position="bot">
    <template #body>
      <h4 class="error-title">
        <q-icon
          name="fa-solid fa-triangle-exclamation"
          color="red"
          size="30px"
        />
        {{ $gettext('Nepodporovaný typ prohlížeče') }}
      </h4>
      <span class="error-text">
        {{ $gettext('Používáte') }}
        <strong>{{ $gettext('nepodporovaný typ prohlížeče.') }}</strong>
        {{ $gettext('Některé funkce aplikace Fyzion vám') }}
        <strong>{{ $gettext('nemusí správně fungovat.') }}</strong>
        {{ $gettext('Prosím') }}
        <strong>{{ $gettext('aktualizujte') }}</strong>
        {{ $gettext('svůj prohlížeč nebo si nainstalujte jiný.') }}
      </span>
      <span class="error-text">
        {{ $gettext('Fyzion funguje') }}
        <strong>{{ $gettext('bezchybně a rychle') }}</strong>
        {{
          $gettext(
            'v nejnovější verzi Google Chrome, kterou můžete zdarma stáhnout na'
          )
        }}
        <a
          class="google-link"
          href="https://www.google.com/chrome/"
          target="_blank"
        >
          {{ $gettext('https://www.google.com/chrome/') }}
        </a>
      </span>
    </template>
    <template #footer>
      <div class="bottom-action-buttons">
        <q-btn
          :label="$gettext('Beru na vědomí')"
          class="btn agreed-btn"
          color="primary"
          unelevated
          ripple
          @click="$emit('closeModal')"
        />
      </div>
    </template>
  </BaseModal>
</template>
<style lang="scss" scoped>
.agreed-btn {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;

  /* Inside auto layout */
}

.error-text {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  /* dark-300 */

  color: #35374b;
}

.error-title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  /* dark-300 */

  color: #35374b;

  /* Inside auto layout */
}

.google-link {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: center;
  text-decoration-line: underline;

  /* main */

  color: #c438e7;
}
</style>
