import { Timestamp } from '@quasar/quasar-ui-qcalendar';

export interface Pagination {
  rowsNumber: number;
  rowsPerPage: number;
  page: number;
  sortBy: string | null;
  descending: boolean;
}

export interface QuasarTableOnRequest {
  pagination: Pagination;
  filter: string;
  getCellValue(row: unknown, col: string): unknown;
}

export interface QCalendarDateClickScope {
  timestamp: Timestamp;
  columnIndex: number;
  activeDate: boolean;
  droppable: boolean;
}

export interface QCalendarDateClick {
  event: object;
  scope: QCalendarDateClickScope;
}

export const VisitsGetVisitListGetQueryEnum = {
  Next: 'NEXT',
  Past: 'PAST',
  All: 'ALL',
} as const;
export type VisitsGetVisitListGetQueryEnum =
  (typeof VisitsGetVisitListGetQueryEnum)[keyof typeof VisitsGetVisitListGetQueryEnum];
