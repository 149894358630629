import { route } from 'quasar/wrappers';
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
  Router,
} from 'vue-router';

import routes from './routes';
import { useUser } from 'stores/user/useUser';
import { captureMessage } from '@sentry/vue';
import { dynamicLoadingErrorMessages } from 'src/router/dynamicLoadingErrorMessages';
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export let globalRouterInstance: Router | undefined = undefined;

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
    ? createWebHistory
    : createWebHashHistory;

  const routerInstance = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  routerInstance.beforeEach(async (to, from, next) => {
    const store = useUser();

    if (!store.loggedIn) {
      if (to.matched.some((route) => route.meta.public)) {
        return next();
      }
      return next({ name: 'login' });
    }
    if (!store.user) {
      await store.getUser();
    }
    if (to.matched.some((route) => route.name === 'auth')) {
      return next({ name: 'home' });
    }
    return next();
  });

  routerInstance.onError((error: Error, to) => {
    if (
      process.env.ENVIRONMENT !== 'localhost' &&
      dynamicLoadingErrorMessages.some((errorMessage) =>
        error.message.toLowerCase().includes(errorMessage.toLowerCase())
      )
    ) {
      captureMessage(
        'Triggered automatic reload after failed to fetch dynamically imported module.',
        {
          extra: {
            message: error.message,
          },
        }
      );
      window.location.href = to.fullPath;
    }
  });
  globalRouterInstance = routerInstance;
  return routerInstance;
});
