import parse from 'date-fns/parse';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';

// usages of this can be later replaced by a dynamic timezone
export const timezone = 'Europe/Prague';

export interface Time {
  hours: number;
  minutes: number;
}

export const getTimeAtTimezone = (tz: string): Time => {
  const date = utcToZonedTime(new Date(), tz);
  // const date = new Date(new Date().toLocaleString('en-US', {timeZone: tz}));
  return {
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };
};
const isValidDate = (d: unknown): boolean => {
  return d instanceof Date && !isNaN(d.getTime());
};

export function tryParseDate(
  dateInput: string | null | undefined
): Date | null {
  if (!dateInput) return null;
  const result = new Date(dateInput);
  return isValidDate(result) ? result : null;
}

export function isDateSimilarToEpoch(date: Date): boolean {
  // Check if the provided date is a valid Date object and its getTime() value is equal to 0.
  return date.getTime() === 0;
}

export function dateFormatter(dateinput: string): string {
  const dateSplit = dateinput.replace(/\s/g, '').split(/[/\-.]/, 3);
  let date = '';
  for (let i = 0; i < 2; i++) {
    if (dateSplit[i].length === 1) {
      date = date + '0' + dateSplit[i] + '.';
    }
    if (dateSplit[i].length === 2) {
      date = date + dateSplit[i] + '.';
    }
  }
  date = date + dateSplit[2];
  return date;
}

export function timeFormatter(hours: number, minutes: number): string {
  let formattedHours: string | undefined = '';
  let formattedMinutes: string | undefined = '';
  if (hours.toString().length === 2) {
    formattedHours = `${hours}`;
  } else {
    formattedHours = `0${hours}`;
  }
  if (minutes.toString().length === 2) {
    formattedMinutes = `${minutes}`;
  } else {
    formattedMinutes = `0${minutes}`;
  }
  return `${formattedHours}:${formattedMinutes}`;
}

export const getFormattedDate = (
  date: Date | string,
  tz: string | undefined = undefined
): string => {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  if (tz) {
    date = utcToZonedTime(date, tz);
  }
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${day}.${month}.${year}`;
};

export const getFormattedDateNoYear = (
  date: Date,
  tz: string | undefined
): string => {
  if (tz) {
    date = utcToZonedTime(date, tz);
  }
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${day}. ${month}.`;
};

export const getFormattedTime = (
  date: Date | null | undefined | string,
  tz: string | undefined = undefined
): string => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  if (!date) {
    return '';
  }
  if (tz) {
    date = utcToZonedTime(date, tz);
  }
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const getFormattedHours = (
  date: Date | undefined,
  tz: string | undefined = undefined
): string => {
  if (!date) {
    return '';
  }
  if (tz) {
    date = utcToZonedTime(date, tz);
  }
  const hours = date.getHours().toString().padStart(2, '0');
  return `${hours}:00`;
};

export const getParsedDateTime = (date: string, time: string): Date => {
  return parse(
    `${dateFormatter(date)} ${time}`,
    'dd.MM.yyyy HH:mm',
    new Date()
  );
};

export const getParsedDate = (date: string): Date => {
  return parse(`${date}`, 'dd.MM.yyyy', new Date());
};

export const formatDate = (
  date: Date | string | null | undefined,
  tz: string | undefined = undefined
): string => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  if (!date) {
    return '';
  }
  if (isDateSimilarToEpoch(date)) {
    return '';
  }
  return new Intl.DateTimeFormat(navigator.language, { timeZone: tz }).format(
    date
  );
};

export const formatDateTwoDigits = (
  date: Date | string | null | undefined,
  tz: string | undefined
): string => {
  if (!date) {
    return '';
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  if (isDateSimilarToEpoch(date)) {
    return '';
  }
  return new Intl.DateTimeFormat(navigator.language, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: tz,
  }).format(date);
};

export const formatDateNoYear = (
  date: Date | undefined | string | null,
  tz: string | undefined
): string => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  const options = { day: '2-digit', month: '2-digit', timeZone: tz };
  return new Intl.DateTimeFormat(navigator.language, options).format(date);
};

export const getStartOfCurrentWeek = (): Date => {
  const today = new Date();
  return new Date(today.setDate(today.getDate() - ((today.getDay() + 6) % 7)));
};

export const getStartOfLastWeek = (): Date => {
  const today = new Date();
  return new Date(
    today.setDate(today.getDate() - ((today.getDay() + 6) % 7) - 7)
  );
};

export const getEndOfWeek = (startOfWeek: Date): Date => {
  return new Date(startOfWeek.setDate(startOfWeek.getDate() + 6));
};

export const getEndOfCurrentMonth = (): Date => {
  const today = new Date();
  return new Date(Date.UTC(today.getFullYear(), today.getMonth() + 1, 0));
};

export const getStartOfLastMonth = (): Date => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth() - 1, 1);
};

export const getEndOfLastMonth = (): Date => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), 0);
};

export const getStartOfThisYear = (): Date => {
  const today = new Date();
  return new Date(today.getFullYear(), 0, 1);
};

export const getEndOfThisYear = (): Date => {
  const today = new Date();
  return new Date(today.getFullYear(), 11, 31);
};

export const getStartOfLastYear = (): Date => {
  const today = new Date();
  return new Date(today.getFullYear() - 1, 0, 1);
};

export const getEndOfLastYear = (): Date => {
  const today = new Date();
  return new Date(today.getFullYear() - 1, 11, 31);
};

export const formatDateToRangePickerFormat = (date: Date): string => {
  // Extract year, month, and day from the Date object
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Adding 1 to get the correct month
  const day = date.getDate();

  // Format the date as 'YYYY/MM/DD'
  return `${year}/${String(month).padStart(2, '0')}/${String(day).padStart(
    2,
    '0'
  )}`;
};

export const formatFromRangePickerFormat = (value: string): Date => {
  const dateParts = value.split('/');
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // Months are zero-based in JavaScript Date
  const day = parseInt(dateParts[2]);

  return new Date(Date.UTC(year, month, day));
};

export const formatDateTooltip = (
  date: Date | undefined,
  tz: string | undefined
): string => {
  if (!date) {
    return '';
  }
  if (tz) {
    date = utcToZonedTime(date, tz);
  }

  const weekday = date.toLocaleDateString(navigator.language, {
    weekday: 'long',
  });
  const stringDate = date.toLocaleDateString(navigator.language, {
    month: 'long',
    day: 'numeric',
  });
  return weekday + ', ' + stringDate;
};

export const fullBirthDate = (
  day: number | null,
  monthIndex: number | null | undefined,
  year: number | null
): string => {
  if (day === null || monthIndex === null || year === null) {
    return '';
  }

  // Constructing the Date object
  const date = new Date(year, monthIndex, day);

  // Returning the localized date string
  return date.toLocaleDateString(navigator.language);
};
