/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthURLSchema } from '../models/AuthURLSchema';
import type { GoogleCalendarSyncSchema } from '../models/GoogleCalendarSyncSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GoogleAuthApi {
    /**
     * Get Auth Url
     * Get google calendar authorization URL.
     * @returns AuthURLSchema OK
     * @throws ApiError
     */
    public static googleAuthGetAuthUrlGet({
        customerUserId,
        redirectUrl,
    }: {
        customerUserId: string,
        redirectUrl: string,
    }): CancelablePromise<AuthURLSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/google-auth/google-auth-url/',
            query: {
                'customer_user_id': customerUserId,
                'redirect_url': redirectUrl,
            },
        });
    }
    /**
     * Revoke Google Access
     * Revoke google access.
     * @returns void
     * @throws ApiError
     */
    public static googleAuthRevokeGoogleAccessDelete({
        customerUserId,
    }: {
        customerUserId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/google-auth/revoke-google-access/',
            query: {
                'customer_user_id': customerUserId,
            },
        });
    }
    /**
     * Sync Google Calendar
     * Sync google calendar.
     * @returns any OK
     * @throws ApiError
     */
    public static googleAuthSyncGoogleCalendarPost({
        requestBody,
    }: {
        requestBody: GoogleCalendarSyncSchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/google-auth/sync-google-calendar/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
