/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MedicalActionListSchema } from './MedicalActionListSchema';
export type FTTicketActionSchema = {
    id: string;
    ft_ticket_action_id: string;
    medical_action: MedicalActionListSchema;
    count: number;
    points_total: number;
    empty_count: number;
    type?: FTTicketActionSchema.type;
};
export namespace FTTicketActionSchema {
    export enum type {
        EMPTY = 'empty',
    }
}

