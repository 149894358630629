/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ContractCareType {
    _15 = '15',
    _19 = '19',
    _49 = '49',
    _66 = '66',
}
