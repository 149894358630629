/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LogoutSchema } from '../models/LogoutSchema';
import type { PasswordResetConfirm } from '../models/PasswordResetConfirm';
import type { PasswordResetRequest } from '../models/PasswordResetRequest';
import type { RefreshResponse } from '../models/RefreshResponse';
import type { RefreshSchema } from '../models/RefreshSchema';
import type { SignIn } from '../models/SignIn';
import type { SignUp } from '../models/SignUp';
import type { TokenSchema } from '../models/TokenSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthApi {
    /**
     * Logout
     * @returns void
     * @throws ApiError
     */
    public static authLogoutDelete({
        requestBody,
    }: {
        requestBody: LogoutSchema,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/auth/logout/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Password Reset Request
     * @returns any OK
     * @throws ApiError
     */
    public static authPasswordResetRequestPost({
        requestBody,
    }: {
        requestBody: PasswordResetRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/auth/password-reset/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Post Password Reset Confirm
     * @returns any OK
     * @throws ApiError
     */
    public static authPostPasswordResetConfirmPost({
        requestBody,
    }: {
        requestBody: PasswordResetConfirm,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/auth/password-reset-confirm/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Refresh
     * @returns RefreshResponse OK
     * @throws ApiError
     */
    public static authRefreshPost({
        requestBody,
    }: {
        requestBody: RefreshSchema,
    }): CancelablePromise<RefreshResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/auth/refresh/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Sign In
     * @returns TokenSchema OK
     * @throws ApiError
     */
    public static authSignInPost({
        requestBody,
    }: {
        requestBody: SignIn,
    }): CancelablePromise<TokenSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/auth/sign-in/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Sign Up
     * @returns void
     * @throws ApiError
     */
    public static authSignUpPost({
        requestBody,
    }: {
        requestBody: SignUp,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/auth/sign-up/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
