export function isSupportedBrowser(): boolean {
  const userAgentString = navigator.userAgent;
  interface BrowserVersions {
    Chrome: number;
    Firefox: number;
    Safari: number;
    Edge: number;
    Opera: number;
  }

  const supportedBrowsers: { [key in keyof BrowserVersions]: number } = {
    Chrome: 87,
    Firefox: 69,
    Safari: 14.0,
    Edge: 87,
    Opera: 73,
  };

  let browserName: string | undefined;
  let browserVersion: string | undefined;

  // Check for Chrome
  if (userAgentString.includes('Chrome')) {
    browserName = 'Chrome';
    browserVersion = userAgentString.match(/Chrome\/(\d+)/)?.[1];
    if (!browserVersion) {
      browserVersion = userAgentString.match(/CriOS\/(\d+)/)?.[1];
    }
  }
  // Check for Firefox
  else if (userAgentString.includes('Firefox')) {
    browserName = 'Firefox';
    browserVersion = userAgentString.match(/Firefox\/(\d+)/)?.[1];
  }
  // Check for Safari
  else if (userAgentString.includes('Safari')) {
    browserName = 'Safari';
    browserVersion = userAgentString.match(/Version\/(\d+\.\d+)/)?.[1];
    if (!browserVersion) {
      browserVersion = userAgentString.match(/Safari\/(\d+\.\d+)/)?.[1];
    }
  }
  // Check for Edge
  else if (userAgentString.includes('Edg')) {
    browserName = 'Edge';
    browserVersion = userAgentString.match(/Edg\/(\d+)/)?.[1];
  }
  // Check for Opera
  else if (userAgentString.includes('OPR')) {
    browserName = 'Opera';
    browserVersion = userAgentString.match(/OPR\/(\d+)/)?.[1];
  }
  // If the browser is not supported, return false
  else {
    return false;
  }
  // Check if the browser version is supported
  if (
    browserVersion &&
    supportedBrowsers[browserName as keyof typeof supportedBrowsers] <=
      parseInt(browserVersion)
  ) {
    return true;
  } else {
    return false;
  }
}
