/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ReceiptInvoiceType {
    CORRECTIVE_TAX_DOCUMENT_RECEIPT = 'corrective_tax_document_receipt',
    CORRECTIVE_TAX_DOCUMENT_INVOICE = 'corrective_tax_document_invoice',
    INVOICE = 'invoice',
    RECEIPT = 'receipt',
}
