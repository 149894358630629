/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ReceiptPatientPaymentType {
    CASH = 'cash',
    BANK_TRANSFER = 'bank_transfer',
    CARD = 'card',
    TICKET = 'ticket',
    WEBPAY = 'webpay',
}
