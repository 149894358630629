/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateGroupVisitSchema } from '../models/CreateGroupVisitSchema';
import type { GroupVisitDetailSchema } from '../models/GroupVisitDetailSchema';
import type { UpdateGroupVisitSchema } from '../models/UpdateGroupVisitSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GroupVisitsApi {
    /**
     * Add Patient To Group Visit
     * Add patient to group visit. <br>Required Permission: update_visit
     * @returns GroupVisitDetailSchema Created
     * @throws ApiError
     */
    public static groupVisitsAddPatientToGroupVisitPost({
        groupVisitId,
        patientId,
    }: {
        groupVisitId: string,
        patientId: string,
    }): CancelablePromise<GroupVisitDetailSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/group/{group_visit_id}/patient/{patient_id}/',
            path: {
                'group_visit_id': groupVisitId,
                'patient_id': patientId,
            },
        });
    }
    /**
     * Remove Patient From Group Visit
     * Remove patient from group visit. <br>Required Permission: update_visit
     * @returns void
     * @throws ApiError
     */
    public static groupVisitsRemovePatientFromGroupVisitDelete({
        groupVisitId,
        patientId,
    }: {
        groupVisitId: string,
        patientId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/group/{group_visit_id}/patient/{patient_id}/',
            path: {
                'group_visit_id': groupVisitId,
                'patient_id': patientId,
            },
        });
    }
    /**
     * Create Group Visit
     * @returns GroupVisitDetailSchema Created
     * @throws ApiError
     */
    public static groupVisitsCreateGroupVisitPost({
        requestBody,
    }: {
        requestBody: CreateGroupVisitSchema,
    }): CancelablePromise<GroupVisitDetailSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/group/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Group Visit
     * Delete group visit. <br>Required Permission: delete_visit
     * @returns void
     * @throws ApiError
     */
    public static groupVisitsDeleteGroupVisitDelete({
        groupVisitId,
    }: {
        groupVisitId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/group/{group_visit_id}/',
            path: {
                'group_visit_id': groupVisitId,
            },
        });
    }
    /**
     * Detail Group Visit
     * Get group visit. <br>Required Permission: get_visit_detail
     * @returns GroupVisitDetailSchema OK
     * @throws ApiError
     */
    public static groupVisitsDetailGroupVisitGet({
        groupVisitId,
    }: {
        groupVisitId: string,
    }): CancelablePromise<GroupVisitDetailSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/group/{group_visit_id}/',
            path: {
                'group_visit_id': groupVisitId,
            },
        });
    }
    /**
     * Update Group Visit
     * Update group visit. <br>Required Permission: update_visit
     * @returns GroupVisitDetailSchema OK
     * @throws ApiError
     */
    public static groupVisitsUpdateGroupVisitPatch({
        groupVisitId,
        requestBody,
    }: {
        groupVisitId: string,
        requestBody: UpdateGroupVisitSchema,
    }): CancelablePromise<GroupVisitDetailSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/group/{group_visit_id}/',
            path: {
                'group_visit_id': groupVisitId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
