import { useReservations } from 'src/stores/reservations/useReservations';
import {
  RouteLocationNormalized,
  RouteLocationRaw,
  RouteRecordRaw,
} from 'vue-router';
import gettext from '../utils/gettext';
import { useUser } from 'stores/user/useUser';
import { PermissionsCodename } from 'src/api';
import { PaymentStatusOptions } from 'components/constants';
import { useVisit } from 'stores/visits/useVisitStore';

// for public routes, use meta tag public

const { $gettext } = gettext;
const routes: RouteRecordRaw[] = [
  // home redirect from back
  {
    path: '/home',
    props: true,
    redirect: (): RouteLocationRaw => {
      return { name: 'home' };
    },
    children: [],
  },
  {
    path: $gettext('/domu'),
    alias: '/domu',
    component: () => import('src/layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('pages/dashboard/DashboardPage.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('src/layouts/AppAuthLayout.vue'),
    name: 'auth',
    meta: { public: true },
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('src/pages/LoginPage.vue'),
      },
      {
        path: $gettext('registrace-zdarma'),
        alias: 'registrace-zdarma',
        name: 'signupFree',
        component: () => import('src/pages/SignUpPageFree.vue'),
      },
      {
        path: $gettext('obnova-hesla'),
        alias: 'obnova-hesla',
        name: 'resetEmail',
        component: () => import('src/pages/ResetEmailPage.vue'),
      },
      {
        path: $gettext('resetpassword/:uid/:token'),
        alias: 'resetpassword/:uid/:token',
        name: 'resetPassword', // IF CHANGED - needs to be changed in layouts/AppAuthLayout.vue as well
        props: true,
        component: () => import('src/pages/ResetPasswordPage.vue'),
      },
    ],
  },
  {
    path: $gettext('/pacienti'),
    alias: '/pacienti',
    component: () => import('src/layouts/PatientNavLayout.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'patients',
        component: () => import('src/pages/PatientsPage.vue'),
      },
      {
        path: $gettext('novy'),
        alias: 'novy',
        name: 'new-patient',
        props: (route) => ({
          next: route.query.next,
          isFromNewVisit: route.query.isFromNewVisit,
        }),
        component: () => import('pages/patients/CreatePatientPage.vue'),
      },
      {
        path: $gettext('/cekajici-pacienti'),
        alias: '/cekajici-pacienti',
        props: true,
        children: [
          {
            path: '',
            name: 'patients-wait',
            props: true,
            component: () => import('src/pages/WaitingPatientsPage.vue'),
          },
          {
            path: $gettext('novy'),
            alias: 'novy',
            name: 'new-waiting-patient',
            props: true,
            component: () =>
              import('pages/patients/CreateWaitingPatientPage.vue'),
          },
        ],
      },
      {
        path: ':patientId',
        props: true,
        children: [
          {
            path: $gettext('karta-pacienta'),
            alias: 'karta-pacienta',
            props: true,
            children: [
              {
                path: '',
                name: 'patient-detail',
                props: (route) => ({
                  patientId: route.params.patientId,
                  receiptId: route.query.receiptId,
                  isFromCreatePatient: route.query.isFromCreatePatient,
                }),
                component: () =>
                  import('src/pages/patients/PatientDetailPage.vue'),
              },
              {
                path: $gettext('upravit'),
                alias: 'upravit',
                name: 'edit-patient',
                props: true,
                component: () =>
                  import('src/pages/patients/UpdatePatientPage.vue'),
              },
              {
                path: $gettext('pridat-permanentku'),
                alias: 'pridat-permanentku',
                name: 'new-patient-ticket',
                props: (route) => ({
                  patientId: route.params.patientId,
                  next: route.query.next,
                }),
                component: () =>
                  import('pages/tickets/NewPatientTicketPage.vue'),
              },
              {
                path: $gettext('upravit-permanentku/:ticketId'),
                alias: 'upravit-permanentku/:ticketId',
                name: 'edit-patient-ticket',
                props: (route) => ({
                  ticketId: route.params.ticketId,
                  patientId: route.params.patientId,
                }),
                component: () =>
                  import('src/pages/tickets/EditPatientTicketPage.vue'),
              },
            ],
          },
          {
            path: $gettext('anamneza'),
            alias: 'anamneza',
            name: 'patient-medical-history',
            props: true,
            component: () =>
              import('src/pages/patients/PatientMedicalHistoryPage.vue'),
          },
          {
            path: $gettext('navstevy-pacienta'),
            alias: 'navstevy-pacienta',
            props: true,
            children: [
              {
                path: '',
                name: 'patient-visits',
                props: true,
                component: () =>
                  import('src/pages/patients/PatientVisitsPage.vue'),
              },
              {
                path: ':visitId',
                props: true,
                beforeEnter: async (to, from, next): Promise<void> => {
                  const visitId = to.params.visitId;
                  const visitStore = useVisit();
                  await visitStore.getVisit(visitId.toString());
                  if (!visitStore.visitDetail && visitStore.errors) {
                    return next({
                      name: 'patient-visits',
                      params: {
                        patientId: to.params.patientId,
                      },
                    });
                  }
                  return next();
                },
                children: [
                  {
                    path: '',
                    name: 'visit-detail',
                    props: (route) => ({
                      visitId: route.params.visitId,
                      patientId: route.params.patientId,
                      receiptId: route.query.receiptId,
                    }),
                    component: () =>
                      import('src/pages/patients/VisitDetailPage.vue'),
                  },
                  {
                    path: $gettext('zaplatit'),
                    alias: 'zaplatit',
                    name: 'create-payment',
                    props: (route) => ({
                      visitId: route.params.visitId,
                      patientId: route.params.patientId,
                      next: route.query.next,
                      fromGroup: route.query.fromGroup === 'true',
                    }),
                    component: () => import('pages/patients/PaymentPage.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: $gettext('fotografie'),
            alias: 'fotografie',
            props: true,
            children: [
              {
                path: '',
                name: 'photo-list',
                props: true,
                component: () =>
                  import('src/pages/patients/PhotosListPage.vue'),
              },
              {
                path: $gettext('porovnavac'),
                alias: 'porovnavac',
                name: 'photo-compare',
                props: true,
                component: () =>
                  import('src/pages/patients/PhotosComparerPage.vue'),
              },
            ],
          },
          {
            path: $gettext('nova'),
            alias: 'nova',
            name: 'new-visit-patient',
            props: (route) => ({
              userId: route.query.userId,
              patientId: route.query.patientId,
              time: route.query.time,
              next: route.query.next,
              isFromCreatePatient: route.query.isFromCreatePatient,
              initialRoomId: route.query.initialRoomId,
            }),
            component: () => import('src/pages/VisitCreatePage.vue'),
          },
          {
            path: $gettext('vykony'),
            alias: 'vykony',
            name: 'ft-tickets',
            children: [
              {
                path: '',
                name: 'ft-ticket-list',
                props: true,
                component: () =>
                  import('src/pages/patients/insurance/FTTicketListPage.vue'),
              },
              {
                path: $gettext('novy-ft-poukaz'),
                alias: 'novy-ft-poukaz',
                name: 'new-ft-ticket',
                props: (route) => ({
                  patientId: route.params.patientId,
                  ftCloneId: route.query.ftCloneId,
                }),
                component: () =>
                  import('src/pages/patients/insurance/CreateFtTicketPage.vue'),
              },
              {
                path: $gettext('upravit-ft-poukaz/:ticketId'),
                alias: 'upravit-ft-poukaz/:ticketId',
                name: 'edit-ft-ticket',
                props: (route) => ({
                  ticketId: route.params.ticketId,
                  patientId: route.params.patientId,
                }),
                component: () =>
                  import('src/pages/patients/insurance/UpdateFtTicketPage.vue'),
              },
            ],
          },
        ],
      },
      {
        path: $gettext(':patientId/popisy-navstev'),
        alias: ':patientId/popisy-navstev',
        name: 'visit-descriptions',
        props: true,
        component: () =>
          import('src/pages/patients/PatientVisitDescriptionsPage.vue'),
      },
    ],
  },
  {
    path: $gettext('/kalendar'),
    alias: '/kalendar',
    component: () => import('src/layouts/AppCalendarNavLayout.vue'),
    children: [
      {
        path: '',
        name: 'calendar',
        redirect: { name: 'calendar-users' },
      },
      {
        path: $gettext('fyzioterapeuti'),
        alias: 'fyzioterapeuti',
        name: 'calendar-users',
        props: true,
        component: () => import('pages/calendar/CalendarPage.vue'),
      },
      {
        path: $gettext('mistnosti'),
        alias: 'mistnosti',
        name: 'calendar-rooms',
        props: true,
        component: () => import('pages/calendar/CalendarRoomsPage.vue'),
      },
      {
        path: $gettext('planovani'),
        alias: 'planovani',
        name: 'calendar-planning',
        props: true,
        component: () => import('pages/calendar/CalendarPlanningPage.vue'),
      },
      {
        path: $gettext('planovani-mistnosti'),
        alias: 'planovani-mistnosti',
        name: 'calendar-room-planning',
        props: true,
        component: () => import('pages/calendar/RoomPlaningCalendarPage.vue'),
      },
    ],
  },
  {
    path: $gettext('/akce'),
    alias: '/akce',
    component: () => import('src/layouts/AppLayout.vue'),
    children: [
      {
        path: $gettext('pridat-volno'),
        alias: 'pridat-volno',
        name: 'create-time-off',
        props: (route) => ({
          userId: route.query.userId,
          timeOffDate: route.query.timeOffDate,
          calendarSelectedDate: route.query.calendarSelectedDate,
          next: route.query.next,
        }),
        component: () => import('pages/office/CreateTimeOffPage.vue'),
      },
      {
        path: $gettext('upravit-volno'),
        alias: 'upravit-volno',
        name: 'update-time-off',
        props: (route) => ({
          userId: route.query.userId,
          timeOffId: route.query.timeOffId,
          calendarSelectedDate: route.query.calendarSelectedDate,
          next: route.query.next,
        }),
        component: () => import('pages/office/UpdateTimeOffPage.vue'),
      },
      {
        path: $gettext('nova-navsteva'),
        alias: 'nova-navsteva',
        name: 'new-visit',
        props: (route) => ({
          userId: route.query.userId,
          patientId: route.query.patientId,
          time: route.query.time,
          next: route.query.next,
          isFromCreatePatient: route.query.isFromCreatePatient,
          initialRoomId: route.query.initialRoomId,
          unfinishedVisit: route.query.unfinishedVisit === 'true',
        }),
        component: () => import('src/pages/VisitCreatePage.vue'),
      },
      {
        path: $gettext('nova-skupinova-navsteva'),
        alias: 'nova-skupinova-navsteva',
        name: 'new-group-visit',
        props: (route) => ({
          userId: route.query.userId,
          time: route.query.time,
          next: route.query.next,
          initialRoomId: route.query.initialRoomId,
        }),
        component: () =>
          import('src/pages/group-visit/GroupVisitCreatePage.vue'),
      },
    ],
  },
  {
    path: $gettext('/tisk'),
    alias: '/tisk',
    component: () => import('src/layouts/PrintLayout.vue'),
    children: [
      {
        path: $gettext('karta/:patientId'),
        alias: 'karta/:patientId',
        name: 'print-patient',
        props: true,
        component: () => import('src/pages/print/PrintPatient.vue'),
      },
      {
        path: $gettext('navsteva/:patientId/:visitId'),
        alias: 'navsteva/:patientId/:visitId',
        name: 'print-visit',
        props: true,
        component: () => import('pages/print/PrintVisit.vue'),
      },
      {
        path: $gettext('potvrzeni-pece/:patientId'),
        alias: 'potvrzeni-pece/:patientId',
        name: 'print-visits-confirmation',
        props: (route) => ({
          patientId: route.params.patientId,
          visits: route.query.visits,
        }),
        component: () => import('pages/print/PrintVisitConfirmation.vue'),
      },
    ],
  },
  {
    path: $gettext('/statistiky'),
    alias: '/statistiky',
    component: () => import('src/layouts/AppStatsNavLayout.vue'),
    children: [
      {
        path: '',
        name: 'stats',
        redirect: { name: 'visit-stats' },
      },
      {
        path: $gettext('navstevy'),
        alias: 'navstevy',
        name: 'visit-stats',
        component: () => import('src/pages/stats/VisitStatsPage.vue'),
      },
      {
        path: $gettext('permanentky'),
        alias: 'permanentky',
        name: 'ticket-stats',
        component: () => import('src/pages/stats/TicketStatsPage.vue'),
      },
      {
        path: $gettext('finance'),
        alias: 'finance',
        name: 'finance-stats',
        component: () => import('src/pages/stats/FinanceStatsPage.vue'),
      },
      {
        path: $gettext('fyzioterapeuti'),
        alias: 'fyzioterapeuti',
        name: 'users-stats',
        component: () => import('src/pages/stats/UsersStatsPage.vue'),
      },
    ],
  },
  {
    path: $gettext('/sklad'),
    alias: '/sklad',
    component: () => import('layouts/AppStockNavLayout.vue'),
    beforeEnter(to, from, next): void {
      const userStore = useUser();
      if (!userStore.user?.company_allowed_warehouse) {
        return next({
          name: 'home',
        });
      }
      return next();
    },
    children: [
      {
        path: '',
        name: 'stock',
        redirect: { name: 'supplies' },
      },
      {
        path: $gettext('zasoby'),
        alias: 'zasoby',
        children: [
          {
            path: '',
            name: 'supplies',
            component: () => import('src/pages/stock/StockPage.vue'),
          },
          {
            path: $gettext('naskladneni'),
            alias: 'naskladneni',
            name: 'stock-up',
            component: () => import('src/pages/stock/CreateSupplyPage.vue'),
          },
          {
            path: $gettext('inventura'),
            alias: 'inventura',
            name: 'stock-check',
            component: () => import('src/pages/stock/StockCheckPage.vue'),
          },
        ],
      },
      // TODO: Stock receipts & goods movement will be implemented in the next dev iteration
      // {
      //   path: $gettext('doklady'),
      //   name: 'stock-receipts',
      //   component: () => import('src/pages/stock/StockReceiptsPage.vue'),
      // },
      // {
      //   path: $gettext('pohyb-zbozi'),
      //   name: 'goods-movement',
      //   component: () => import('src/pages/stock/GoodsMovementPage.vue'),
      // },
      {
        path: $gettext('polozky'),
        alias: 'polozky',
        children: [
          {
            path: '',
            name: 'stock-items',
            component: () => import('src/pages/stock/StockItemsPage.vue'),
          },
          {
            path: $gettext('nova'),
            alias: 'nova',
            name: 'stock-item-create',
            component: () => import('src/pages/stock/CreateStockItemPage.vue'),
          },
          {
            path: $gettext(':stockItemId/upravit'),
            alias: ':stockItemId/upravit',
            name: 'stock-item-update',
            props: true,
            component: () => import('src/pages/stock/UpdateStockItemPage.vue'),
          },
        ],
      },
      {
        path: $gettext('dodavatele'),
        alias: 'dodavatele',
        children: [
          {
            path: '',
            name: 'suppliers',
            component: () => import('src/pages/stock/SuppliersPage.vue'),
          },
          {
            path: $gettext('novy'),
            alias: 'novy',
            name: 'supplier-create',
            props: (route) => ({
              next: route.query.next, // handle redirects after the supplier is either created or user goes back from the create form
            }),
            component: () => import('src/pages/stock/CreateSupplierPage.vue'),
          },
          {
            path: $gettext(':supplierId/upravit'),
            alias: ':supplierId/upravit',
            name: 'supplier-update',
            props: true,
            component: () => import('src/pages/stock/UpdateSupplierPage.vue'),
          },
        ],
      },
    ],
  },
  {
    path: $gettext('/nastaveni'),
    alias: '/nastaveni',
    component: () => import('layouts/AppSettingsNavLayout.vue'),
    children: [
      {
        path: '',
        name: 'settings',
        redirect: { name: 'basic-settings' },
      },
      {
        path: $gettext('obecne'),
        alias: 'obecne',
        children: [
          {
            path: '',
            name: 'basic-settings',
            component: () => import('src/pages/settings/SettingsPage.vue'),
          },
          {
            path: 'sms',
            name: 'sms-settings',
            component: () =>
              import('src/pages/settings/sms/SMSSettingsPage.vue'),
          },
          {
            path: 'gdpr',
            name: 'gdpr-content',
            component: () =>
              import('src/pages/settings/gdpr/GDPRContentPage.vue'),
          },
        ],
      },
      {
        path: $gettext('rezervace'),
        alias: 'rezervace',
        name: 'reservations',
        component: () => import('src/pages/settings/ReservationsPage.vue'),
      },
      {
        path: $gettext('sablony'),
        alias: 'sablony',
        children: [
          {
            path: '',
            name: 'templates',
            component: () => import('src/pages/settings/TemplatesPage.vue'),
          },
          {
            path: $gettext('nova'),
            alias: 'nova',
            name: 'new-template',
            props: (route) => ({
              next: route.query.next,
            }),
            component: () =>
              import('src/pages/settings/CreateTemplatePage.vue'),
          },
          {
            path: $gettext('upravit') + '/:templateId',
            alias: 'upravit/:templateId',
            name: 'update-template',
            props: (route) => ({
              templateId: route.params.templateId,
              next: route.query.next,
            }),
            component: () =>
              import('src/pages/settings/UpdateTemplatePage.vue'),
          },
        ],
      },
      {
        path: $gettext('fakturacni-udaje'),
        alias: 'fakturacni-udaje',
        name: 'billing-data',
        component: () => import('src/pages/settings/BillingDataPage.vue'),
      },
      {
        path: $gettext('kontaktni-udaje'),
        alias: 'kontaktni-udaje',
        name: 'contact-information',
        component: () =>
          import('src/pages/settings/ContactInformationPage.vue'),
      },
      {
        path: $gettext('recenze'),
        name: 'reviews-list',
        component: () => import('src/pages/settings/ReviewsListPage.vue'),
      },
    ],
  },
  {
    path: $gettext('/ordinace'),
    alias: '/ordinace',
    component: () => import('layouts/AppOfficeNavLayout.vue'),
    children: [
      {
        path: '',
        name: 'office',
        redirect: { name: 'receipts' },
      },
      {
        path: $gettext('sluzby'),
        alias: 'sluzby',
        children: [
          {
            path: '',
            name: 'services',
            component: () => import('pages/office/ServicesPage.vue'),
          },
          {
            path: $gettext('nova'),
            alias: 'nova',
            name: 'services-new',
            component: () =>
              import('pages/office/services/ServiceCreatePage.vue'),
          },
          {
            path: $gettext(':serviceId/upravit'),
            alias: ':serviceId/upravit',
            name: 'services-update',
            props: true,
            component: () =>
              import('pages/office/services/ServiceUpdatePage.vue'),
          },
          {
            path: $gettext('kategorie'),
            alias: 'kategorie',
            children: [
              {
                path: $gettext('nova'),
                alias: 'nova',
                name: 'service-groups-new',
                component: () =>
                  import('pages/office/services/ServiceGroupCreatePage.vue'),
                props: (route) => ({
                  next: route.query.next,
                }),
              },
              {
                path: $gettext(':serviceGroupId/upravit'),
                alias: ':serviceGroupId/upravit',
                name: 'service-groups-update',
                props: true,
                component: () =>
                  import('pages/office/services/ServiceGroupUpdatePage.vue'),
              },
            ],
          },
        ],
      },
      {
        path: $gettext('permanentky'),
        alias: 'permanentky',
        children: [
          {
            path: '',
            name: 'permanent-tickets',
            component: () => import('pages/office/TicketsPage.vue'),
          },
          {
            path: $gettext('nova'),
            alias: 'nova',
            name: 'ticket-create',
            component: () => import('pages/office/TicketsCreatePage.vue'),
          },
          {
            path: ':ticketId/' + $gettext('upravit'),
            alias: ':ticketId/upravit',
            name: 'ticket-update',
            props: true,
            component: () => import('pages/office/TicketsUpdatePage.vue'),
          },
        ],
      },
      {
        path: $gettext('fyzioterapeuti'),
        alias: 'fyzioterapeuti',
        children: [
          {
            path: '',
            name: 'users',
            component: () => import('pages/office/UsersPage.vue'),
          },
          {
            path: $gettext('novy'),
            alias: 'novy',
            name: 'create-user',
            component: () => import('src/pages/users/NewUserPage.vue'),
          },
          {
            path: $gettext(':userId/upravit'),
            alias: ':userId/upravit',
            name: 'edit-user',
            props: true,
            component: () => import('src/pages/users/UpdateUserPage.vue'),
          },
          {
            path: $gettext(':userId/ordinacni-hodiny'),
            alias: ':userId/ordinacni-hodiny',
            name: 'working-hours',
            props: true,
            component: () => import('src/pages/users/WorkingHoursPage.vue'),
          },
        ],
      },
      {
        path: $gettext('platby'),
        alias: 'platby',
        children: [
          {
            path: '',
            name: 'receipts',
            component: () =>
              import('pages/office/receipts/ReceiptsListPage.vue'),
          },
          {
            path: ':receiptId',
            children: [
              {
                path: $gettext('upravit'),
                alias: 'upravit',
                name: 'update-receipt',
                props: true,
                component: () =>
                  import('pages/office/receipts/ReceiptUpdatePage.vue'),
              },
            ],
          },
          {
            path: $gettext('nova'),
            alias: 'nova',
            name: 'create-receipt',
            props: true,
            component: () =>
              import('pages/office/receipts/ReceiptCreatePage.vue'),
          },
        ],
      },
      {
        path: $gettext('vykony'),
        alias: 'vykony',
        children: [
          {
            path: '',
            name: 'medical-actions-list',
            component: () =>
              import('pages/office/medical-actions/MedicalActionsListPage.vue'),
          },
        ],
      },
      {
        path: $gettext('predplatne'),
        alias: 'predplatne',
        children: [
          {
            path: '',
            name: 'subscriptions',
            component: () =>
              import('pages/office/subscriptions/SubscriptionsListPage.vue'),
          },
          {
            path: $gettext('nove'),
            alias: 'nove',
            name: 'create-subscription',
            component: () =>
              import('pages/office/subscriptions/CreateSubscriptionPage.vue'),
          },
          {
            path: ':subscriptionId',
            children: [
              {
                path: '',
                name: 'subscription-detail',
                props: (route) => ({
                  subscriptionId: route.params.subscriptionId,
                  receiptId: route.query.receiptId,
                }),
                component: () =>
                  import(
                    'pages/office/subscriptions/SubscriptionsDetailPage.vue'
                  ),
              },
              {
                path: $gettext('upravit'),
                alias: 'upravit',
                name: 'update-subscription',
                props: true,
                component: () =>
                  import(
                    'pages/office/subscriptions/UpdateSubscriptionPage.vue'
                  ),
              },
              {
                path: $gettext('faktury'),
                alias: 'faktury',
                children: [
                  {
                    path: $gettext('nova'),
                    alias: 'nova',
                    name: 'create-subscription-payment',
                    props: true,
                    component: () =>
                      import(
                        'pages/office/subscriptions/CreateSubscriptionPaymentPage.vue'
                      ),
                  },
                  {
                    path: ':subscriptionPaymentId/' + $gettext('upravit'),
                    alias: ':subscriptionPaymentId/upravit',
                    name: 'update-subscription-payment',
                    props: true,
                    component: () =>
                      import(
                        'pages/office/subscriptions/UpdateSubscriptionPaymentPage.vue'
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: $gettext('mistnosti'),
        alias: 'mistnosti',
        children: [
          {
            path: '',
            name: 'rooms',
            component: () => import('pages/office/rooms/RoomsListPage.vue'),
          },
          {
            path: $gettext('nova'),
            alias: 'nova',
            name: 'create-room',
            component: () => import('pages/office/rooms/RoomsCreatePage.vue'),
          },
          {
            path: ':roomId',
            children: [
              {
                path: $gettext('upravit'),
                alias: 'upravit',
                name: 'update-room',
                props: true,
                component: () =>
                  import('pages/office/rooms/RoomsUpdatePage.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: $gettext('/pojistovny'),
    alias: '/pojistovny',
    name: 'insurance',
    redirect: { name: 'insurance-batches-list' },
    component: () => import('src/layouts/AppInsuranceCompaniesNavLayout.vue'),
    beforeEnter: (to, from, next): void => {
      const userStore = useUser();
      if (
        !userStore.user?.company_allowed_insurance ||
        !userStore.handleUserHasPermission(
          PermissionsCodename.VIEW_INSURANCES_TAB
        )
      ) {
        return next({
          name: 'home',
        });
      }
      return next();
    },
    children: [
      {
        path: $gettext('nastaveni'),
        alias: 'nastaveni',
        name: 'insurance-settings',
        component: () =>
          import('pages/insurance-companies/InsuranceSettingsPage.vue'),
        beforeEnter: (to, from, next): void => {
          const userStore = useUser();
          if (
            !userStore.handleUserHasPermission(
              PermissionsCodename.LIST_MY_COMPANY_INSURANCE_SETTINGS
            )
          ) {
            return next({
              name: 'home',
            });
          }
          return next();
        },
      },
      {
        path: $gettext('davky'),
        alias: 'davky',
        name: 'insurance-batches',
        beforeEnter: (to, from, next): void => {
          const userStore = useUser();
          if (
            !userStore.handleUserHasPermission(
              PermissionsCodename.GET_K_BATCH_LIST
            )
          ) {
            return next({
              name: 'insurance-applicants', // this is here to allow workers access insurances tab
            });
          }
          return next();
        },
        children: [
          {
            path: '',
            name: 'insurance-batches-list',
            component: () =>
              import(
                'pages/insurance-companies/insurance-batches/InsuranceBatchListPage.vue'
              ),
          },
          {
            path: $gettext('nova'),
            alias: 'nova',
            name: 'insurance-batch-create',
            component: () =>
              import(
                'pages/insurance-companies/insurance-batches/InsuranceOriginalBatchCreatePage.vue'
              ),
          },
          {
            path: ':kBatchId',
            children: [
              {
                path: $gettext('detail'),
                alias: 'detail',
                name: 'insurance-batch-detail',
                props: (route) => ({
                  kBatchId: route.params.kBatchId,
                }),
                component: () =>
                  import(
                    'pages/insurance-companies/insurance-batches/InsuranceBatchDetailPage.vue'
                  ),
              },
              {
                path: $gettext('nova-opravna'),
                alias: 'nova-opravna',
                name: 'insurance-corrective-batch-create',
                props: (route) => ({
                  kBatchId: route.params.kBatchId,
                  next: route.query.next,
                }),
                component: () =>
                  import(
                    'pages/insurance-companies/insurance-batches/InsuranceCorrectiveBatchCreatePage.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: $gettext('pracoviste'),
        alias: 'pracoviste',
        beforeEnter: (to, from, next): void => {
          const userStore = useUser();
          if (
            !userStore.handleUserHasPermission(
              PermissionsCodename.GET_COMPANY_INSURANCE_WORKSPACE_LIST
            )
          ) {
            return next({
              name: 'home',
            });
          }
          return next();
        },
        children: [
          {
            path: $gettext('nove'),
            alias: 'nove',
            name: 'insurance-company-workspace-create',
            component: () =>
              import(
                'pages/insurance-companies/insurance-company-workspaces/InsuranceCompanyWorkspaceCreatePage.vue'
              ),
          },
          {
            path: $gettext(':companyWorkspaceId/pridat-pojistovnu'),
            alias: ':companyWorkspaceId/pridat-pojistovnu',
            name: 'insurance-create',
            props: (route) => ({
              companyWorkspaceId: route.params.companyWorkspaceId,
            }),
            component: () =>
              import(
                'pages/insurance-companies/insurance-company-workspaces/InsuranceCreatePage.vue'
              ),
          },
          {
            path: $gettext(
              ':companyWorkspaceId/upravit-pojistovnu/:insuranceContractId'
            ),
            alias:
              ':companyWorkspaceId/upravit-pojistovnu/:insuranceContractId',
            name: 'insurance-update',
            props: (route) => ({
              insuranceContractId: route.params.insuranceContractId,
              companyWorkspaceId: route.params.companyWorkspaceId,
            }),
            component: () =>
              import(
                'pages/insurance-companies/insurance-company-workspaces/InsuranceUpdatePage.vue'
              ),
          },
          {
            path: '',
            name: 'insurance-list',
            props: (route) => ({
              newWorkspace: route.query.newWorkspace,
            }),
            component: () =>
              import(
                'pages/insurance-companies/insurance-company-workspaces/InsuranceListPage.vue'
              ),
          },
          {
            path: $gettext(':companyWorkspaceId/upravit'),
            alias: ':companyWorkspaceId/upravit',
            name: 'insurance-company-workspace-update',
            props: (route) => ({
              companyWorkspaceId: route.params.companyWorkspaceId,
            }),
            component: () =>
              import(
                'pages/insurance-companies/insurance-company-workspaces/InsuranceCompanyWorkspaceUpdatePage.vue'
              ),
          },
        ],
      },
      {
        path: $gettext('zadatele'),
        alias: 'zadatele',
        beforeEnter: (to, from, next): void => {
          const userStore = useUser();
          if (
            !userStore.handleUserHasPermission(
              PermissionsCodename.GET_INSURANCE_WORKSPACE_LIST
            )
          ) {
            return next({
              name: 'home',
            });
          }
          return next();
        },
        children: [
          {
            path: '',
            name: 'insurance-applicants',
            component: () =>
              import(
                'pages/insurance-companies/insurance-applicants/InsuranceApplicantListPage.vue'
              ),
          },
          {
            path: $gettext('novy'),
            alias: 'novy',
            name: 'insurance-applicant-create',
            props: (route) => ({
              next: route.query.next,
            }),
            component: () =>
              import(
                'pages/insurance-companies/insurance-applicants/InsuranceApplicantCreatePage.vue'
              ),
          },
          {
            path: $gettext(':insuranceApplicantId/upravit'),
            alias: ':insuranceApplicantId/upravit',
            name: 'insurance-applicant-update',
            props: true,
            component: () =>
              import(
                'pages/insurance-companies/insurance-applicants/InsuranceApplicantUpdatePage.vue'
              ),
          },
        ],
      },
    ],
  },
  // calendar sync redirect from back
  {
    path: '/profile/synchronize-calendar',
    redirect: (): RouteLocationRaw => {
      return { name: 'calendar-sync' };
    },
  },
  {
    path: $gettext('/profil'),
    alias: '/profil',
    component: () => import('src/layouts/AppProfileNavLayout.vue'),
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import('src/pages/profile/ProfilePage.vue'),
      },
      {
        path: $gettext('platby'),
        alias: 'platby',
        name: 'payments',
        component: () => import('src/pages/profile/PaymentsPage.vue'),
      },
      {
        path: $gettext('tarif'),
        alias: 'tarif',
        name: 'subscription-plan',
        component: () => import('src/pages/SubscriptionPage.vue'),
      },
      {
        path: $gettext('synchronizace-kalendare'),
        alias: 'synchronizace-kalendare',
        name: 'calendar-sync',
        component: () => import('src/pages/profile/CalendarSyncPage.vue'),
      },
    ],
  },
  {
    path: $gettext('/historie-akci'),
    alias: '/historie-akci',
    component: () => import('src/layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        name: 'action-history',
        component: () => import('src/pages/ActionHistoryPage.vue'),
      },
    ],
  },
  // redirect from back after payment
  {
    path: '/payments',
    name: 'payments-redirect',
    props: true,
    component: () => import('layouts/AppLayout.vue'),
    redirect: (to: RouteLocationNormalized): RouteLocationRaw => {
      const paymentStatus = to.query.payment_status;
      if (paymentStatus === PaymentStatusOptions.PAID) {
        return { name: 'successfull-payment' };
      } else if (paymentStatus === PaymentStatusOptions.REJECTED) {
        return { name: 'failed-payment' };
      } else {
        return { name: 'home' }; // or redirect to a default/fallback route
      }
    },
    children: [],
  },
  {
    path: $gettext('/platba'),
    alias: '/platba',
    component: () => import('layouts/AppLayout.vue'),
    children: [
      {
        path: $gettext('dekujeme'),
        alias: 'dekujeme',
        name: 'successfull-payment',
        component: () => import('src/pages/ThankYouPage.vue'),
      },
      {
        path: $gettext('chyba'),
        alias: 'chyba',
        name: 'failed-payment',
        component: () => import('src/pages/RejectedPage.vue'),
      },
    ],
  },
  {
    path: $gettext('/rozsireni-kartoteky-zdarma'),
    alias: '/rozsireni-kartoteky-zdarma',
    component: () => import('layouts/AppFullWidthLayout.vue'),
    children: [
      {
        path: '',
        name: 'freemium-limit-upgrade',
        component: () => import('src/pages/ShareAppPage.vue'),
      },
    ],
  },
  // reservation redirect from back after payment
  {
    path: '/reservation/:companyId/4-step/:visit_id?',
    props: true,
    redirect: (to: RouteLocationNormalized): RouteLocationRaw => {
      if (to.query.payment_status === PaymentStatusOptions.REJECTED) {
        return { name: 'reservations-error' };
      }
      return { name: 'reservation-fourth-step' };
    },
    children: [],
  },
  {
    path: '/reservations/:companyId',
    alias: ['/rezervace/:companyId', '/reservation/:companyId'],
    component: () => import('layouts/ReservationLayout.vue'),
    redirect: { name: 'reservation-first-step' },
    props: true,
    meta: {
      public: true,
    },
    children: [
      {
        path: '1-step',
        alias: '1-krok',
        name: 'reservation-first-step',
        props: true,
        component: () => import('src/pages/reservation/FirstStepPage.vue'),
      },
      {
        path: '2-step',
        alias: '2-krok',
        name: 'reservation-second-step',
        props: true,
        component: () => import('src/pages/reservation/SecondStepPage.vue'),
        beforeEnter: (to, from, next): void => {
          const reservationStore = useReservations();
          if (!reservationStore.hasSecondStepAccess) {
            return next({
              name: 'reservation-first-step',
              params: { companyId: to.params.companyId },
            });
          }
          return next();
        },
      },
      {
        path: '3-step',
        alias: '3-krok',
        name: 'reservation-third-step',
        props: true,
        component: () => import('src/pages/reservation/ThirdStepPage.vue'),
        beforeEnter: (to, from, next): void => {
          const reservationStore = useReservations();
          if (!reservationStore.hasThirdStepAccess) {
            return next({
              name: 'reservation-second-step',
              params: { companyId: to.params.companyId },
            });
          }
          return next();
        },
      },
      {
        path: '4-step/:visit_id?',
        alias: '4-krok/:visit_id?',
        name: 'reservation-fourth-step',
        props: true,
        component: () => import('src/pages/reservation/FourthStepPage.vue'),
        beforeEnter: async (to, from, next): Promise<void> => {
          const reservationStore = useReservations();
          const visitId = to.query.visit_id || to.params.visit_id;
          if (visitId) {
            await reservationStore.GetVisitReservation(visitId.toString());
          }
          if (!reservationStore.hasFourthStepAccess) {
            return next({
              name: 'reservation-first-step',
              params: { companyId: to.params.companyId },
            });
          }
          return next();
        },
      },
      {
        path: 'cancellation',
        alias: 'zruseni',
        name: 'reservation-cancellation',
        component: () =>
          import('src/pages/reservation/CancelReservationPage.vue'),
        beforeEnter: (to, from, next): void => {
          if (from.name !== 'reservation-fourth-step') {
            return next({
              name: 'reservation-first-step',
              params: { companyId: to.params.companyId },
            });
          }
          return next();
        },
      },
      {
        path: 'error',
        name: 'reservations-error',
        props: true,
        component: () =>
          import('src/pages/reservation/ReservationErrorPage.vue'),
      },
    ],
  },
  // gdpr redirect from back
  {
    path: '/gdpr/agreement/:patientId',
    props: true,
    redirect: (): RouteLocationRaw => {
      return { name: 'gdpr-consent' };
    },
    children: [],
  },
  {
    path: '/reviews/:companyId',
    component: () => import('layouts/ReviewsLayout.vue'),
    redirect: { name: 'visit-review' },
    props: true,
    meta: {
      public: true,
    },
    children: [
      {
        path: 'visit/:visitId',
        name: 'visit-review',
        props: true,
        component: () => import('src/pages/reviews/VisitReviewPage.vue'),
      },
      {
        path: 'thank-you',
        name: 'successful-review',
        props: true,
        component: () => import('src/pages/reviews/ThankYouReviewPage.vue'),
      },
    ],
  },
  {
    path: '/gdpr',
    alias: '/gdpr',
    component: () => import('layouts/GdprConsentLayout.vue'),
    meta: {
      public: true,
    },
    children: [
      {
        path: ':companyId',
        name: 'gdpr-readonly',
        props: true,
        component: () =>
          import('pages/gdpr-consent/GdprConsentReadonlyPage.vue'),
      },
      {
        path: 'agreement/:patientId',
        alias: ['souhlas/:patientId', '/gdpr-souhlas/:patientId'],
        name: 'gdpr-consent',
        props: true,
        component: () => import('pages/gdpr-consent/GdprConsentPage.vue'),
      },
    ],
  },
  {
    path: $gettext('/skupinove-navstevy/'),
    alias: '/skupinove-navstevy/',
    component: () => import('src/layouts/AppLayout.vue'),
    children: [
      {
        path: ':groupVisitId',
        props: (route) => ({
          groupVisitId: route.params.groupVisitId,
          receiptId: route.query.receiptId,
        }),
        name: 'group-visit-detail',
        component: () => import('pages/group-visit/GroupVisitPage.vue'),
      },
    ],
  },
  {
    path: '/impersonate',
    component: () => import('pages/ImpersonatePage.vue'),
    meta: {
      public: true,
    },
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
